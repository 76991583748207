import * as actiontypes from "../actions/actionTypes";
const userReducer = (
  state = window.sessionStorage.getItem("User"),
  action
) => {
  switch (action.type) {
    case actiontypes.GetUser:
      return (state = window.sessionStorage.getItem("User"));
    default:
      return state;
  }
};
export default userReducer;
