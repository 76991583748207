import React, { Component } from "react";
import Image from "../../images/logo.svg"
import axios from "axios"
import { bindActionCreators } from "redux";
import { login } from "../../redux/actions/authAction";
import { getUser } from "../../redux/actions/userAction";
import {connect} from "react-redux"
import "../../../src/App.css";
import * as HttpHelper from'../../utils/HttpHelper';
import history from "../../history";
import alertify from "alertifyjs";
import jwtDecode from "jwt-decode";

 class Login extends Component {
    state={Email:'',Password:''}
    onChangeHandler=(event)=>{
        let name=event.target.name;
        let  value=event.target.value;
        this.setState({[name]:value})
    }
    getUser=async (email,token)=>{
    await axios.get( 
        HttpHelper.url( "api/users/getbyuseremail?email=") +
        email,token).then(response => {
          sessionStorage.setItem('User',JSON.stringify(response.data));
          this.props.dispatch(getUser());   
          history.push("/");
        }).catch(error => {
         
        });;
    }
    onSubmitHandler=async (event)=>{
        event.preventDefault();
        await axios.post(HttpHelper.url('api/auth/login'),this.state).then(response => {
          sessionStorage.setItem('Token',response.data.token);
          this.props.dispatch(login());   
          this.getUser(jwtDecode(response.data.token).email,response.data.token);
        }).catch(error => {
          alertify.error(error.message, 4);
        });
        
    }
  render() {
    return (


      <div className="container">
      <div className="login-container m-auto">
        <img className="logo" src={Image} alt="" />
        
            <form   onSubmit={this.onSubmitHandler}>
              <div className="form-row form-group">
                <div className="col-12">
                  <label
                    className="label-white"
                    data-error="wrong"
                    data-success="right"
                    htmlFor="email"
                    >Email</label>
                  <input
                    type="text"
                    className="form-control validate"
                    id="email"
                    name="Email"
                    required=""
                    onChange={this.onChangeHandler}
                  />
                </div>
              </div>
              <div className="form-row form-group">
                <div className="col-12 ">
                  <label
                    className="label-white"
                    data-error="wrong"
                    data-success="right"
                    htmlFor="Password"
                    >Şifre</label>

                  <input
                    type="password"
                    className="form-control validate"
                    id="Password"
                    name="Password"
                    required=""
                    onChange={this.onChangeHandler} 
                  />
                </div>
              </div>

              <div className="form-row form-group mb-0">
                <div className="col-12">
                  <button className="btn btn-success w-100" type="submit" >
                    GİRİŞ
                  </button>
                </div>
              </div>
           
            {/* <div href="#" className="text-center forgot-pw">
              Forgot Password
            </div> */}
        </form>
      </div>
    </div>

    )
  }
}
// function mapDispatchToProps(dispatch){
//   return{actions:{bindActionCreators(login,dispatch)
//   ,}}
// }
function mapDispatchToProps(dispatch) {
  return {
    actions: {     
      //changeTheme: (data) => dispatch(changeTheme(data)),
      login: bindActionCreators(login, dispatch),
      getuser: bindActionCreators(getUser, dispatch),
    },
  };
}
export default connect (mapDispatchToProps)(Login);


