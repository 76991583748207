import React, { Component } from 'react';
import { Button } from 'reactstrap';
import axios from 'axios';
import history from '../../history';
import alertify from 'alertifyjs';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import * as moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import S3 from 'react-aws-s3';
import * as Config from '../../config';
import { Buffer } from 'buffer';
class NewUser extends Component {
  state = {
    Id: 0,
    Countries: [],
    CountryId: '',
    Email: '',
    Imagelocation: '',
    FirstName: '',
    LastName: '',
    Phone: '',
    File: '',
    Loading: false,
    Roles: [],
    date: new Date(),
    Address: '',
    RoleId: 1,
    selected: [],
    Gender: '0',
    NickName: '',
    IsAdd: true,
  };
  async componentDidMount() {
      await axios
        .get(Config.ApiUrl + 'api/country/getall')
        .then((c) => {
          this.setState({ Countries: c.data });
        })
        .catch((error) => {
          console.log(error.response);
        });
      if(this.state.Countries.length>0)
      this.setState({ CountryId: this.state.Countries[0].id });     
  }
  handleChange = (date) => {
    this.setState({
      date: date,
    });
  };
  onChangeHandler = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value });
  };
  onRoleHandler = (event) => {
    this.setState({ RoleId: event.target.value });
  };
  onGenderHandler = (event) => {
    this.setState({ Gender: event.target.value });
  };
  onSubmitHandler = async (event) => {
    event.preventDefault();
    this.setState({ Loading: true });
    if (this.state.File !== '') {
      window.Buffer = Buffer;
      const ReactS3Client = new S3(Config.UserImageConfig);
     
   await  ReactS3Client
    .uploadFile(this.state.File, this.state.File.name)
    .then(data => {
      this.setState({ Imagelocation: data.location });
      })
    .catch(err => console.error(err));
     }
    let obj = {
      Id: this.state.Id,
      Email: this.state.Email,
      FirstName: this.state.FirstName,
      LastName: this.state.LastName,
      Phone: this.state.Phone,
      ImagePath: this.state.Imagelocation,
      Address: this.state.Address,
      BirthDay: moment(this.state.date).format('MM.DD.YYYY'),
      RoleId: parseInt(this.state.RoleId),
      Gender: parseInt(this.state.Gender),
      NickName: this.state.NickName,
    };
    await axios
      .post(Config.ApiUrl + 'api/auth/register', obj)
      .then((response) => {
        if (parseInt(this.state.Id) === 0) {
          alertify.success(response.data.message, 4);
          this.setState({ Id: parseInt(response.data.data.id) });
        } else alertify.success(response.data, 4);
      })
      .catch((error) => {
        alertify.error(error.response.data, 4);
      });
    this.setState({ Loading: false });
      history.push({
        pathname: '/'
      });
  
  };

  handleFile = (e) => {
    var blob = e.target.files[0].slice(0, e.target.files[0].size);
    var newFile = new File(
      [blob],
      e.target.files[0].name.substring(0, e.target.files[0].name.indexOf('.')) +
        Date.now() +
        '.png',
      { type: 'image/png' }
    );
    this.setState({ File: newFile });
  };
  render() {
    return (
      <div>
        <div className="content-title">New User</div>

        <form action="" onSubmit={this.onSubmitHandler}>
          <div className="row">

              <div className="form-group col-12 col-sm-6 col-lg-3">
                <label htmlFor="exampleEmail">Email</label>
                <input
                  className="form-control"
                  type="email"
                  name="Email"
                  id="exampleEmail"
                  value={this.state.Email}
                  onChange={this.onChangeHandler}
                  required
                />
              </div>
            
            <div className="form-group col-12 col-sm-6 col-lg-3">
              <label htmlFor="FirstName">Takma Ad</label>
              <input
                className="form-control"
                name="NickName"
                id="NickName"
                onChange={this.onChangeHandler}
                value={this.state.NickName}
                required
              />
            </div>


            <div className="form-group col-12 col-sm-6 col-lg-3">
              <label htmlFor="FirstName">Ad</label>
              <input
                className="form-control"
                name="FirstName"
                id="FirstName"
                onChange={this.onChangeHandler}
                value={this.state.FirstName}
              />
            </div>
            <div className="form-group col-12 col-sm-6 col-lg-3">
              <label htmlFor="LastName">Soyad</label>
              <input
                className="form-control"
                name="LastName"
                id="LastName"
                onChange={this.onChangeHandler}
                value={this.state.LastName}
              />
            </div>
            <div className="form-group col-12 col-sm-6 col-lg-3">
                <label>Profil Resmi</label>
                <input
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  onChange={this.handleFile}
                />
              </div>
            <div className="form-group col-12 col-sm-6 col-lg-3">
              <label htmlFor="Phone">Telefon</label>
              <input
                className="form-control"
                name="Phone"
                id="Phone"
                onChange={this.onChangeHandler}
                value={this.state.Phone}
              />
            </div>

            <div className="form-group col-12 col-sm-6 col-lg-3">
              <label htmlFor="exampleDate">Doğum Tarihi</label>
              <div className="form-select">
                <DatePicker
                  className="form-control"
                  selected={this.state.date}
                  onChange={this.handleChange}
              
                />
              </div>
            </div>
            <div className="form-group col-12 col-sm-6 col-lg-3">
              <label htmlFor="name">Cinsiyet</label>
              <div className="form-select">
                <select
                  name="Gender"
                  id="Gender"
                  onChange={this.onGenderHandler}
                  className="form-control"
                  value={this.state.Gender}
                >
                  <option value="0"> Erkek</option>
                  <option value="1"> Kadın </option>
                </select>
              </div>
            </div>
            <div className="form-group col-12 col-sm-6 col-lg-3">
                <label htmlFor="exampleBoy">Boy</label>
                <input
                  className="form-control"
                  type="number"
                  name="Boy"
                  id="exampleBoy"
                  value={this.state.Boy}
                  onChange={this.onChangeHandler}
                  required
                />
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-3">
                <label htmlFor="exampleKilo">Kilo</label>
                <input
                  className="form-control"
                  type="number"
                  name="Kilo"
                  id="exampleKilo"
                  value={this.state.Kilo}
                  onChange={this.onChangeHandler}
                  required
                />
              </div>
          </div>
          <Button
            color="success"
            className="mr-3"
            type="submit"
            disabled={this.state.Loading}
          >
            {!this.state.Loading && (
              <span>{this.state.IsAdd === true ? 'Kayıt Ol' : 'Güncelle'}</span>
            )}
          </Button>
        </form>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return { token: state.authReducer,user:state.userReducer  };
}
export default connect(mapStateToProps)(NewUser);
