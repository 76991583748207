import * as actiontypes from '../actions/actionTypes';

const initialState = {
  loading: localStorage.getItem('loading') || false,
};
function loadingReducer(state = initialState, action) {
  switch (action.type) {
    case actiontypes.ChangeLoading:
      return { ...state, loading: action.data };
    default:
      return state;
  }
}

export default loadingReducer;