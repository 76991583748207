import React, { Component } from "react";

import Post from "../PostList";
import BaseView from "../BaseView";
import { bindActionCreators } from "redux";
import { logaut } from "../../redux/actions/authAction";
import { connect } from "react-redux";
import "../../../src/App.css";
// import avatar from "../../images/avatar.png";

import { changeTheme } from "../../redux/actions/rootAction";
class layout extends Component {
  state = {
    operationClaims: [],
    user: JSON.parse(this.props.user),
    isOpen: false,
    setIsOpen: false,
  };
  // shouldComponentUpdate(nextProps, nextState) {
  //   if (this.props.theme !== nextProps.theme) {
  //     return false;
  //   }

  //   return true;
  // }
  // async componentDidMount() {
  //   if (this.props.token) {
  //     axios.defaults.headers.common[
  //       "Authorization"
  //     ] = `Bearer ${this.props.token}`;
  //     await axios
  //       .get(
  //         Config.ApiUrl +
  //           "api/users/getbyuseremail?email=" +
  //           jwtDecode(this.props.token).email
  //       )
  //       .then((r) => {
  //         this.setState({ user: r.data });
  //         this.setState({ operationClaims: r.data.operationClaims });
  //       })
  //       .catch((error) => {
  //         console.log(error.response);
  //       });
  //   } else {
  //   }
  // }

 
  toggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }
  render() {
    return (
      <div>
             {/* {this.props.token && (
            <BaseView />
             )} */}
             <Post user={this.state.user} token={this.props.token}/>
      </div>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      logaut: bindActionCreators(logaut, dispatch),
      changeTheme: (data) => dispatch(changeTheme(data)),
    },
  };
}
function mapStateToProps(state) {
  return { token: state.authReducer, theme: state.rootReducer.theme,user:state.userReducer };
}
export default connect(mapStateToProps, mapDispatchToProps)(layout);
