import React, { Component } from "react";
import { Button } from "reactstrap";
import * as Config from "../../config";
import axios from "axios";
import update from "immutability-helper";
import { connect } from 'react-redux';

class HowManyCalorie extends Component {
  state = {
    SearchWord: "",
    Foods: [],
    Loading: false,
    User: JSON.parse(this.props.user),
  };
  onChangeHandler = (event) => {
    event.preventDefault();
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value });
  };



  onSubmitHandler = async (event) => {
    event.preventDefault();
    this.setState({ Loading: true });
    await axios
      .get(
        Config.ApiUrl +
          "api/calorie/getcaloriesearchview?searchWord=" +
          this.state.SearchWord
      )
      .then((response) => {
        this.setState({ Foods: response.data });
      })
      .catch((error) => {});


    this.setState({ Loading: false });
  };
  render() {
    const unitChange=(event,foodid)=>{
       const index= this.state.Foods.findIndex(i=>i.id===foodid);
       let changedObject= this.state.Foods[index];
       let selectedUnitId=parseInt(event.target.value);
       if(selectedUnitId!==0)
       {
        let selectedUnit=this.state.Foods[index].units.filter(i=>i.id===selectedUnitId)[0];
        changedObject.selectedUnitWeight=selectedUnit.weight;
        changedObject.selectedUnitId=selectedUnit.id;
        changedObject.selectedUnitValue=1;
        console.log(changedObject.selectedUnitWeight);
        // changedObject.selectedCalorieValue=changedObject.selectedUnitWeight*changedObject.selectedUnitValue*(changedObject.calorieValue/100);
        // changedObject.selectedCarbValue=changedObject.selectedUnitWeight*changedObject.selectedUnitValue*(changedObject.carbValue/100);
       }
       else{//Gram girilmiş
        changedObject.selectedUnitId=0;
        changedObject.selectedUnitValue=100;
        changedObject.selectedUnitWeight=1;

       }
       changedObject.selectedCalorieValue=(changedObject.selectedUnitWeight*changedObject.selectedUnitValue)*(changedObject.calorieValue/100);
       changedObject.selectedCarbValue= (changedObject.selectedUnitWeight*changedObject.selectedUnitValue)*(changedObject.carbValue/100);
       changedObject.selectedFatValue= (changedObject.selectedUnitWeight*changedObject.selectedUnitValue)*(changedObject.fatValue/100);
       changedObject.selectedProteinValue= (changedObject.selectedUnitWeight*changedObject.selectedUnitValue)*(changedObject.proteinValue/100);
       var newData = update(this.state.Foods, {
        $splice: [[index, 1, changedObject]]
    });
    this.setState({Foods: newData});

    };
    const unitValueChange=(event,foodid)=>{
      const index= this.state.Foods.findIndex(i=>i.id===foodid);
      let changedObject= this.state.Foods[index];
      changedObject.selectedUnitValue=event.target.value;
      changedObject.selectedCalorieValue= (changedObject.selectedUnitWeight*changedObject.selectedUnitValue)*(changedObject.calorieValue/100);
      changedObject.selectedCarbValue= (changedObject.selectedUnitWeight*changedObject.selectedUnitValue)*(changedObject.carbValue/100);
      changedObject.selectedFatValue= (changedObject.selectedUnitWeight*changedObject.selectedUnitValue)*(changedObject.fatValue/100);
      changedObject.selectedProteinValue= (changedObject.selectedUnitWeight*changedObject.selectedUnitValue)*(changedObject.proteinValue/100);
      var newData = update(this.state.Foods, {
        $splice: [[index, 1, changedObject]]
    });
      this.setState({Foods: newData});
    };
     const food = async (event,food) => {
      event.preventDefault();
      this.setState({ Loading: true });
      let obj = {       
        MealId:parseInt(isNaN(food.selectedMealId)?1:food.selectedMealId),
        UnitValue:parseFloat(isNaN(food.selectedUnitValue)?100:food.selectedUnitValue),
        CalorieId:parseInt(food.id),
        UnitId:parseInt(isNaN(food.selectedUnitId)?0:food.selectedUnitId),
        UserId:this.state.User.id
      };
      console.log(obj,food);
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.token}`;
      await axios
      .post(Config.ApiUrl + "api/usercalorie/add", obj)
      .then((response) => {
       // alertify.success(response.data.message, 4);
      })
      .catch((error) => {
       // alertify.error(error.response.data, 4);
      });
      this.setState({ Loading: false });
    };
    
    return (
      <div>

        <div className="col-12">
        <form onSubmit={this.onSubmitHandler}>
          <div className="row align-items-end">
            <div className="form-group col-12 col-sm-11 col-lg-11">
              <label htmlFor="SearchWord">
                Ara (Marka,besin veya restoran...)
              </label>
              <input
                className="form-control"
                name="SearchWord"
                id="SearchWord"
                onChange={this.onChangeHandler}
              />
            </div>
            <div className="form-group col-12 col-sm-1 col-lg-1">
              <Button
                type="submit"
                color="primary"
                disabled={this.state.Loading}
              >
                {this.state.Loading && (
                  <i className="ri-loader-4-line ri-spin"></i>
                )}
                {!this.state.Loading && <span> Ara</span>}
                {this.state.Loading && <span> Yükleniyor ...</span>}
              </Button>
            </div>
          </div>
        </form>
          <div className="table-wrapper">
            <div className="table table-responsive">
              <div className="ttop">
                <div className="thead">
                  <div className="tr">
                    <div className="td"></div>
                    <div className="td"></div>
                    <div className="td"></div>
                    <div className="td"></div>
                    <div className="td"></div>
                    {this.props.token && (
                      <div>                    
                        <div className="td"></div>
                        <div className="td"></div>
                      </div>
                      )}

                  </div>
                </div>
                <div className="tbody">
                  {this.state.Foods.map((f) => (
                    <div className="tr" key={f.id}>
                      <div className="td">
                        <img
                        className="profile-img"
                        alt=""
                        src={f.imageUrl} />
                      </div>
                      <div className="td">{f.name}</div>
                      <div className="td">
                    
              <div className="col-12 ">
                <select
                  className="form-control"
                  onChange={(event)=> unitChange(event,f.id)}
                  type="select"
                >
                  <option key={0}value={0}>Gram</option>
                  {f.units.map((unit) => (
                    <option key={unit.id} value={unit.id}>
                      {unit.unitName}
                    </option>
                  ))}
                </select>
          </div>
                      </div>
                      <div className="td">
                      <input
                        className="form-control"
                        step="1"
                        type="number"
                        value={f.selectedUnitValue}
                        onChange={(event)=> unitValueChange(event,f.id)}
                />           
                      </div>
                      <div className="td"><p>{f.selectedCalorieValue.toFixed(1)}-{f.selectedCarbValue.toFixed(1)}-{f.selectedFatValue.toFixed(1)}-{f.selectedProteinValue.toFixed(1)} <br/>kcal-karb-Yağ-Protein</p></div>
                      {this.props.token && (
                        <div>
                          <div className="td">
                          <select
                            className="form-control"
                            type="select"
                          >
                            <option key={0}value={1}>Sabah</option>
                            <option key={1}value={2}>Öğlen</option>
                            <option key={2}value={3}>Akşam</option>
                            <option key={3}value={4}>Aparatif</option>
                          </select>
                          </div>
                          <div className="td">
                          <Button
                           color="success"
                           type="submit"
                          //  onClick={(event)=> onSaveCalorie(event,f)}
                          > 
                          <span>Ekle</span> 
                         </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return { token: state.authReducer,user:state.userReducer };
}
export default connect(mapStateToProps)(HowManyCalorie);
