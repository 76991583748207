import React, { Component } from 'react'
import ReactSpeedometer from "react-d3-speedometer"
import { Button } from 'reactstrap';
export default class BodyMass extends Component {
  state = {
    Boy: 0,
    Kilo:0,
    BodyMassCalcuteted:0
  };
  onChangeHandler = (event) => {
    event.preventDefault();
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value });
   
  };
  onSubmitHandler = async (event) => {
    event.preventDefault();
    var calcuted=this.state.Kilo/((this.state.Boy/100)*(this.state.Boy/100));
    this.setState({BodyMassCalcuteted:calcuted.toFixed(2)});
  };
  render() {
    return (
      <div>
        <div className="content-title">Vücut Kitle İndeksi</div>
        <form action="" onSubmit={this.onSubmitHandler}>
        <div className="row">
        <div className="form-group col-12 col-sm-6 col-lg-3">
                <label htmlFor="exampleBoy">Boy</label>
                <input
                  className="form-control"
                  type="number"
                  name="Boy"
                  id="exampleBoy"
                  value={this.state.Boy}
                  onChange={this.onChangeHandler}
                  required
                />
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-3">
                <label htmlFor="exampleKilo">Kilo</label>
                <input
                  className="form-control"
                  type="number"
                  name="Kilo"
                  id="exampleKilo"
                  value={this.state.Kilo}
                  onChange={this.onChangeHandler}
                  required
                />
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-3">
              <Button
            color="success"
            className="mt-4"
            type="submit"
          > 
              <span>Hesapla</span> 
          </Button>
          </div>
              <div className="form-group col-12 col-sm-6 col-lg-6">
              <ReactSpeedometer
               forceRender={false}
              maxSegmentLabels={1}
              segmentColors={['#ff0000','#ffff00','#00ff00','#ffff00', '#ff0000']}
              needleColor={'#0072ff'}
              currentValueText={'Kitle Endeksi: ${value}'}
              minValue={0}
              maxValue={50}
              value={parseInt(this.state.BodyMassCalcuteted)}
              customSegmentStops={[0,16,18.5,25, 30, 50]}
      //textColor={textColor}
              />
              </div>
           </div>
        </form>
      </div>
    )
  }
}
