import React, { Component } from "react";
import history from "../../history";
import axios from "axios";
import Carousel from "../Carousel";
import * as Config from "../../config";
import MicrowaveIcon from "@mui/icons-material/Microwave";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import { orange } from "@mui/material/colors";
import { DefaultizedPieValueType } from "@mui/x-charts";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import CalculateIcon from '@mui/icons-material/Calculate';
export default class RecipeDetail extends Component {
  state = {
    RecipeImages: [],
    Recipe: {},
    RecipeCalories: [],
    ChartData:[],
    TotalCarb:0,
    TotalFat:0,
    TotalProtein:0,
    TotalCalorie:0,
    TotalSugar:0,
  };

  async componentDidMount() {
    if (history.location.state && history.location.state.id) {
      const recipeId = parseInt(history.location.state.id);
      await axios
        .get(Config.ApiUrl + "api/recipe/getrecipedetail?recipeId=" + recipeId)
        .then((r) => {
          this.setState({ RecipeImages: r.data.recipeImages });
          this.setState({ Recipe: r.data.recipe });
          this.setState({ RecipeCalories: r.data.recipeCalories });
          console.log(r.data.recipe);
        })
        .catch((error) => {});
      let ImageList = [];
      for (let index = 0; index < this.state.RecipeImages.length; index++) {
        let obj = {
          src: this.state.RecipeImages[index].url,
        };
        ImageList.push(obj);
      }
      this.setState({ RecipeImages: ImageList });
      let  totalCalorie=(this.state.RecipeCalories.reduce(function (total, current) {
        return total + current.unitTotalCalorie;
      }, 0)/this.state.Recipe.capacity).toFixed(1);
      this.setState({TotalCalorie:totalCalorie});

      let  totalSugar=(this.state.RecipeCalories.reduce(function (total, current) {
        return total + current.unitTotalSugar;
      }, 0)/this.state.Recipe.capacity).toFixed(1);
      this.setState({TotalSugar:totalSugar});
     
      let totalKarb=(this.state.RecipeCalories.reduce(function (total, current) {
        return total + current.unitTotalCarb;
      }, 0)/this.state.Recipe.capacity).toFixed(1);
      this.setState({TotalCarb:totalKarb});
      let totalFat=(this.state.RecipeCalories.reduce(function (total, current) {
        return total + current.unitTotalFat;
      }, 0)/this.state.Recipe.capacity).toFixed(1);
      this.setState({TotalFat:totalFat});
      let totalProtein= (this.state.RecipeCalories.reduce(function (total, current) {
        return total + current.unitTotalProtein;
      }, 0)/this.state.Recipe.capacity).toFixed(1);
      this.setState({TotalProtein:totalProtein});
      let data = [
        { label: "Karb.",
         value: parseFloat(this.state.TotalCarb),
          color: "#0088FE" },
        { label: "Yağ",
        value: parseFloat(this.state.TotalFat),
         color: "#00C49F" },
        { label: "Protein",
        value: parseFloat(this.state.TotalProtein),
         color: "#FFBB28" }
      ];
      this.setState({ChartData:data});
      console.log(this.state.RecipeCalories);
    }
    console.log(this.state.Recipe);
  }
  render() {
    const data = this.state.ChartData;
    const TOTAL = data.map((item) => item.value).reduce((a, b) => a + b, 0);
    const getArcLabel = (params: DefaultizedPieValueType) => {
      console.log(TOTAL);
      const percent = params.value / TOTAL;
      return `${(percent * 100).toFixed(0)}%`;//+params.label;
    };
    return (
      <div>
        <div className="content-title">{this.state.Recipe.name}</div>
        <h5>
          <RestaurantIcon color="primary" style={{ fontSize: "24px" }} />{" "}
          Hazırlık:{this.state.Recipe.prepareTime} dk{" "}
          <MicrowaveIcon
            sx={{ color: orange[500] }}
            style={{ fontSize: "24px" }}
          />{" "}
          Pişirme:{this.state.Recipe.cookingTime} dk{" "}
          <Diversity1Icon color="primary" style={{ fontSize: "24px" }} />{" "}
          {this.state.Recipe.capacity} Porsiyon
          <CalculateIcon     sx={{ color: orange[500] }} style={{ fontSize: "24px" }} />{" "}
          {this.state.TotalCalorie} Kalori(1 Porsiyon için)
          
        </h5>
        <div className="row">
          <div className=" col-12 col-sm-12 col-lg-6 ">
            <div className="video-container">
              <iframe
                className="img-fluid w-100"
                src={this.state.Recipe.videoUrl}
                frameborder="0"
                allow="autoplay; encrypted-media"
                allowfullscreen
                title="video"
              />
            </div>
          </div>
          <div className=" col-12 col-sm-12 col-lg-6 ">
           
            <PieChart
              series={[
                {
                  outerRadius: 80,
                  data,
                  arcLabel: getArcLabel,
                },
              ]}
              sx={{
                [`& .${pieArcLabelClasses.root}`]: {
                  fill: "white",
                  fontSize: 14,
                },
              }}
          
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-12 col-lg-6 mt-1 ">
            <h5>Hazırlanışı</h5>
            <span >{this.state.Recipe.explanation}</span>
          </div>
          <div className="col-12 col-sm-12 col-lg-6 mt-1 ">
          <h5>Besin Değerleri (1 Porsiyon İçin)</h5>
          <div className="table-wrapper">
              <div className="table table-responsive">
                <div className="ttop">
                  <div className="thead">
                    <div className="tr">
                      <div className="td">Kalori</div>
                      <div className="td">Karbonhidrat</div>
                      <div className="td">Yağ</div>
                      <div className="td">Protein</div>
                      <div className="td">Şeker</div>
                    </div>
                  </div>
                  <div className="tbody">
                  <div className="tr">
                        <div className="td">{this.state.TotalCalorie}</div>
                        <div className="td">{this.state.TotalCarb} gr</div>
                        <div className="td">{this.state.TotalFat} gr</div>
                        <div className="td">{this.state.TotalProtein} gr</div>
                        <div className="td">{this.state.TotalSugar} gr</div>
                  </div>
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6 col-lg-6 mt-2">
            <Carousel
              className="img-fluid w-100"
              Images={this.state.RecipeImages}
            />
          </div>
          <div className="col-12 col-sm-6 col-lg-6 mt-2">
            <span className="row h4 col-12">Mazemeler</span>
            <div className="table-wrapper">
              <div className="table table-responsive">
                <div className="ttop">
                  <div className="thead">
                    <div className="tr">
                      <div className="td"></div>
                      <div className="td">Miktar</div>
                      <div className="td">Besin</div>
                      {/* <div className="td">Sil</div> */}
                    </div>
                  </div>
                  <div className="tbody">
                    {this.state.RecipeCalories.map((f) => (
                      <div className="tr" key={f.id}>
                        <div className="td">
                          <img
                            className="profile-img"
                            alt=""
                            src={f.calorieImageUrl}
                          />
                        </div>
                        <div className="td">
                          {f.unitValue}-{f.unitName}
                        </div>
                        <div className="td">{f.calorieName}</div>
                        {/* <div className="td">{ <Button onClick={() => this.deleteFood(f)}>Sil</Button> }</div> */}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
