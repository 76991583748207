import * as Config from '../config';
import axios from "axios"
//import alertify from "alertifyjs";
//import { Token } from 'aws-sdk/lib/token';
export const url=(endPoint)=>{
    return Config.ApiUrl+endPoint;
}
export const post= async (endPoint,data)=>{
    const requestUrl=url(endPoint);
    //  return  await axios.post(requestUrl+"/login?Email=aasdliliv@gmail.com&Password=1234").then(response => response)
    return  await axios.post(requestUrl,data).then(response => response)
        .catch(error =>console.log(error.data));
}
export const get= async (endPoint,token)=>{
    const requestUrl=url(endPoint);
    console.log(requestUrl);
    //  return  await axios.post(requestUrl+"/login?Email=aasdliliv@gmail.com&Password=1234").then(response => response)
    axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
    return  await axios.get(requestUrl).then(response => response)
        .catch(error =>console.log(error.data));

    
}

