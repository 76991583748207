//export const ApiUrl="http://54.235.19.189:80/";
//export const ApiUrl="https://localhost:44386/";
export const ApiUrl="https://livfits.com/";

export const UserImageConfig = {
  bucketName: "chmybucket",
  dirName: "UserImage",
  region: "eu-north-1",
  accessKeyId: "AKIAQSBZWYGYUFUXUHY2",
  secretAccessKey: "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",
};

// export const S3UserImageconfig = {
//     bucketName: "chmybucket",
//     dirName: "UserImage",
//     region: "eu-north-1",
//     accessKeyId: "AKIAQSBZWYGYUFUXUHY2",
//     secretAccessKey: "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",
//   };

  export const S3CalorieImageconfig = {
    bucketName: "chmybucket",
    dirName: "CalorieImage",
    region: "eu-north-1",
    accessKeyId: "AKIAQSBZWYGYUFUXUHY2",
    secretAccessKey: "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",
  };

  export const RecipeImageConfig = {
    bucketName: "chmybucket",
    dirName: "RecipeImage",
    region: "eu-north-1",
    accessKeyId: "AKIAQSBZWYGYUFUXUHY2",
    secretAccessKey: "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",
  };

  export const PostImageConfig = {
    bucketName: "chmybucket",
    dirName: "PostImage",
    region: "eu-north-1",
    accessKeyId: "AKIAQSBZWYGYUFUXUHY2",
    secretAccessKey: "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",
  };






 