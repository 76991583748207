import React, { Component } from "react";
import history from "../history";
import alertify from "alertifyjs";
import Card from "@mui/material/Card";
import * as moment from "moment";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandMore from "@mui/icons-material/ExpandMore";
import AddCommentIcon from "@mui/icons-material/AddComment";
import Avatar from "@mui/material/Avatar";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { Modal } from "semantic-ui-react";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import S3 from "react-aws-s3";
import { Buffer } from "buffer";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import axios from "axios";
import * as Config from "../config";
import { TrendingUp } from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { connect } from 'react-redux';
import { changeLoading } from '../redux/actions/loadingAction';
class PostList extends Component {
  state = {
    Posts: [],
    ModalShow: false,
    CommentModalShow: false,
    PostComment:'',
    Loading: false,
    Imagelocation: "",
    Explanation: "",
    CommentPostId:0,
    File: [],
  };

  async liked(postid) {
    if (this.props.user) {
      let temp = this.state.Posts;
      let index = temp.findIndex((i) => i.id == postid);
      if (temp[index].liked == false) {
        temp[index].liked = true;
        temp[index].postLikers.push({ id: this.props.user.id });
      } else {
        temp[index].liked = false;
      }
      this.setState({ Posts: temp });

      let obj = {
        UserId: parseInt(this.props.user.id),
        PostId: parseInt(postid),
      };
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.token}`;
      await axios
        .post(Config.ApiUrl + "api/postlike/add", obj)
        .then((response) => {})
        .catch((error) => {});
    } else {
      alertify.error("Beğenebilmek için giriş yapın.", 4);
    }
  }
  onChangeHandler = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value });
  };
  commentPostBridge(id)
  {
    if (this.props.user) {
      
      this.setState({ CommentModalShow: true });
      this.setState({ CommentPostId: parseInt(id)});
   
    } else {
      alertify.error("Yorum yapabilmek için giriş yapın.", 4);
    }
  }
  postModalShow() {  
    if (this.props.user) {
      this.setState({ ModalShow: true });
    } else {
      alertify.error("Paylaşım yapabilmek için giriş yapın.", 4);
    }
  }

  SendComment = async () => {
    if (this.props.user) {
      let obj = {
        UserId: this.props.user.id,
        Explanation: this.state.PostComment,
        PostId:this.state.CommentPostId
      };
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.token}`;

      await axios
        .post(Config.ApiUrl + "api/postcomment/add", obj)
        .then((response) => {
          alertify.success("Yorum paylaşıldı!", 4);
          history.push("/");
        })
        .catch((error) => {
          alertify.error(error.response.data, 4);
        });
        this.setState({ CommentModalShow: false });
    } else {
      alertify.error("Yorum yapabilmek için giriş yapın.", 4);
    }
  };
  onSubmitHandler = async (event) => {
    if (this.props.user) {
      this.props.actions.changeLoading(true);
      this.setState({ Loading: true });
      this.setState({ ModalShow: false });
      if (this.state.File !== "") {
        window.Buffer = Buffer;
        const ReactS3Client = new S3(Config.PostImageConfig);

        await ReactS3Client.uploadFile(this.state.File, this.state.File.name)
          .then((data) => {
            this.setState({ Imagelocation: data.location });
          })
          .catch((err) => console.error(err));
      }
      let obj = {
        UserId: this.props.user.id,
        ImageUrl: this.state.Imagelocation,
        Explanation: this.state.Explanation,
      };
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.token}`;

      await axios
        .post(Config.ApiUrl + "api/post/add", obj)
        .then((response) => {
          alertify.success("Gönderi paylaşıldı!", 4);
          history.push("/");
        })
        .catch((error) => {
          alertify.error(error.response.data, 4);
        });
      this.setState({ Loading: false });
      this.props.actions.changeLoading(false);
    } else {
      alertify.error("Paylaşım için giriş yapın.", 4);
    }
  };
  uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  }
  handleFile = (e) => {
    var blob = e.target.files[0].slice(0, e.target.files[0].size);
    var newFile = new File([blob], this.uuidv4() + ".png", {
      type: "image/png",
    });
    this.setState({ File: newFile });
  };

  async componentDidMount() {
    this.props.actions.changeLoading(true);
    let userid = 0;
    if (this.props.user) {
      userid = this.props.user.id;
    }
    await axios
      .get(Config.ApiUrl + "api/post/getall?userid=" + parseInt(userid))
      .then((c) => {
        this.setState({ Posts: c.data });
        this.props.actions.changeLoading(false);
      })
      .catch((error) => {
        console.log(error.response);
        this.props.actions.changeLoading(false);
      });
     
  }
  render() {
    return (
      <div>
        <Modal
          onClose={() => this.setState({ ModalShow: false })}
          open={this.state.ModalShow}
        >
          <Modal.Header>Gönderi Paylaş</Modal.Header>
          <Modal.Content>
            <div className="row">
              <div className="col-12">
                <div className="form-group col-12">
                  <label htmlFor="Explanation">Yorum:</label>
                  <textarea
                    className="form-control"
                    name="Explanation"
                    id="Explanation"
                    onChange={this.onChangeHandler}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-6">
                <label>Resim Ekle</label>
                <input
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  onChange={this.handleFile}
                  multiple
                />
              </div>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <button
              className="btn btn-secondary"
              onClick={() => this.setState({ ModalShow: false })}
            >
              VAZGEÇ
            </button>
            <button
              className="btn btn-success ml-1"
              onClick={() => this.onSubmitHandler()}
            >
              PAYLAŞ
            </button>
          </Modal.Actions>
        </Modal>
        <Modal
          onClose={() => this.setState({ CommentModalShow: false })}
          open={this.state.CommentModalShow}
        >
          <Modal.Header>Yorum Yap</Modal.Header>
          <Modal.Content>
          <div className="row">
              <div className="col-12">
                <div className="form-group col-12">
                  <label htmlFor="PostComment">Yorum:</label>
                  <textarea
                    className="form-control"
                    name="PostComment"
                    id="PostComment"
                    onChange={this.onChangeHandler}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12"></div>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <button
              className="btn btn-secondary"
              onClick={() => this.setState({ CommentModalShow: false })}
            >
              VAZGEÇ
            </button>
            <button
              className="btn btn-success ml-1"
              onClick={() => this.SendComment()}
            >
              PAYLAŞ
            </button>
          </Modal.Actions>
        </Modal>
        <div className="row">
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            onClick={() => this.postModalShow()}
          >
            <AddAPhotoIcon fontSize="large" />
            <Typography variant="body1">PAYLAŞ!</Typography>
          </Stack>
        </div>

        <div className="row d-flex justify-content-center align-items-center mt-2">
          {this.state.Posts.map((val) => (
            <div className="card col-lg-8 col-12 mt-2" key={val.id}>
              <div className="card-body">
                <Card>
                  <CardHeader
                    avatar={
                      <Avatar aria-label="recipe">
                        <img
                          className="profile-img "
                          onClick={() =>
                            history.push({
                              pathname: "/UserDetail",
                              state: { id: val.userId },
                            })
                          }
                          src={val.userImageUrl}
                          alt=""
                        />
                      </Avatar>
                    }
                    // action={
                    //   <IconButton aria-label="settings">
                    //     <MoreVertIcon />
                    //   </IconButton>
                    // }
                    title={val.userNick}
                    subheader={moment(val.recordDate).format("DD.MM.YYYY")}
                  />
                  <CardMedia
                    component="img"
                    className="img-responsive w-100"
                    image={val.imageUrl}
                    alt="Recipe Image"
                  />
                  <CardContent>{val.explanation}</CardContent>
                  <CardActions disableSpacing>
                    <IconButton
                      aria-label="add to favorites"
                      color={
                        val.liked &&
                        val.postLikers.some((i) => i.id == this.props.user.id)
                          ? "primary"
                          : ""
                      }
                      onClick={() => this.liked(val.id)}
                    >
                      <FavoriteIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => this.commentPostBridge(val.id)}>
                      <AddCommentIcon />
                    </IconButton>
                    {/* <ExpandMore
                    expand={true}
                    // onClick={handleExpandClick}
                    aria-expanded={true}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore> */}
                  </CardActions>
                  <CardContent>
                     {val.postComments.map((comment) => (
                       <div key={comment.id} className="mt-2">
                        <span className="font-weight-bold">{comment.nickName+':'} </span>{comment.comment}                        
                       </div>
                       ))}
                       </CardContent>
                  {/* <CardContent>
                asdasdasfsfa
                <CardContent>
                asdasdasfsfa
                </CardContent>
                 </CardContent> */}
                </Card>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {     
      changeLoading: (data) => dispatch(changeLoading(data)),
    },
  };
}
function mapStateToProps(state) {
  return { loading:state.loadingReducer.loading };
}
export default connect(mapStateToProps, mapDispatchToProps)(PostList);