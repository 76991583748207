import React, { Component } from "react";
import S3 from "react-aws-s3";
import { Button } from "reactstrap";
import * as Config from "../../config";
import { Buffer } from "buffer";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';
import update from "immutability-helper";
import alertify from 'alertifyjs';
import axios from 'axios';
import history from '../../history';
class RecipeUpload extends Component {
  state = {
    Files: [],
    Loading: false,
    SearchLoading: false,
    PrepareTime:0,
    CookingTime:0,
    Capacity:0,
    SearchWord:'',
    ModalShow:false,
    MeterialModalShow:false,
    Name:'',
    Explanation:'',
    Privaciy:false,
    User: JSON.parse(this.props.user),
    Foods: [],
    SelectedFoods: [],
    RecipeCategoryId:0,
    Id:0,
    //items: [],
  };
  
  deleteImage=(element)=> {
    const newList= this.state.Files.filter(obj => obj !== element);
    this.setState({Files:newList});

  };
  deleteFood=(element)=> {
    const newList= this.state.SelectedFoods.filter(obj => obj.ImageUrl !== element.ImageUrl);
    this.setState({SelectedFoods:newList});

  };
  onChangeHandler = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value });
  };
  handleDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(this.state.Files);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    this.setState({ Files: items });
  };
  handleFile = (e) => {
    //const files=this.state.Files;
    const files = [];
    for (let index = 0; index < e.target.files.length; index++) {
      var blob = e.target.files[index].slice(0, e.target.files[index].size);
      var newFile = new File(
        [blob],
        e.target.files[index].name.substring(
          0,
          e.target.files[index].name.indexOf(".")
        ) +
          Date.now() +
          index.toString() +
          ".png",
        { type: "image/png" }
      );

      files.push({
        id: index.toString(),
        img: URL.createObjectURL(newFile),
        file: newFile,
      });
    }
    this.setState({ Files: files });
  };

  onSubmitHandler = async (event) => {
    event.preventDefault();
    if(this.state.SelectedFoods.length<1)
    {
      alertify.error("Tarifte kullanılacak mazemeleri ekleyiniz!", 4);
    }
    else if(this.state.Files.length<1)
    {
      alertify.error("Tarife resim ekleyiniz!", 4);
    }
    else
    {
      this.setState({ Loading: true });
   
      let obj = {    
        PrepareTime:parseInt(this.state.PrepareTime),
        CookingTime:parseInt(this.state.CookingTime),
        Capacity:parseInt(this.state.Capacity),
        Name:this.state.Name,
        Explanation:this.state.Explanation,
        UserId:this.state.User.id,
        RecipeCategoryId:parseInt(this.state.RecipeCategoryId)
       
      };
      const calories = [];
      for (let index = 0; index < this.state.SelectedFoods.length; index++) {
        let obj = {       
          UnitValue:parseFloat(this.state.SelectedFoods[index].UnitValue),
          CalorieId:parseInt(this.state.SelectedFoods[index].CalorieId),
          UnitId:parseInt(this.state.SelectedFoods[index].UnitId)
        };
        calories.push(obj);
      }
      let sendObj = {    
        Recipe:obj,
        RecipeCalories:calories     
      };
  
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.token}`;
      await axios
      .post(Config.ApiUrl + "api/recipe/add", sendObj)
      .then((response) => {
        if (this.state.Files.length > 0) {
          this.setState({ Id: response.data.data.id });
        }
        else
        {
          alertify.success(response.data.message, 4);
          history.push("/");
        }
      
       
      })
      .catch((error) => {
        alertify.error(error.response.data, 4);
      });
     
  
  
      if (this.state.Files.length > 0) {
        const datas = [];
        window.Buffer = Buffer;
        const ReactS3Client = new S3(Config.RecipeImageConfig);
        for (let index = 0; index < this.state.Files.length; index++) {
          await ReactS3Client.uploadFile(
            this.state.Files[index].file,
            this.state.Files[index].file.name
          )
            .then((data) => {
              let obj = {    
                Name:this.state.Files[index].file.name,
                Url:data.location,
                RecipeId:parseInt(this.state.Id),
                Order:parseInt(this.state.Files[index].id)
              };
              datas.push(obj);
            })
            .catch((err) => console.error(err));
        }
  
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${this.props.token}`;
        await axios
        .post(Config.ApiUrl + "api/recipeimage/add", datas)
        .then((response) => {
          alertify.success("Tarif Başarıyla Eklendi!", 4);
          history.push("/");
        })
        .catch((error) => {
          alertify.error(error.response.data, 4);
        });
  
        this.setState({ Loading: false });
      }
      else
        this.setState({ Loading: false });
    }

  };
  onCalorieSearchSubmitHandler = async (event) => {
    event.preventDefault();
    this.setState({ SearchLoading: true });
    await axios
      .get(
        Config.ApiUrl +
          "api/calorie/getcaloriesearchview?searchWord=" +
          this.state.SearchWord
      )
      .then((response) => {
        this.setState({ Foods: response.data });
      })
      .catch((error) => {});


    this.setState({ SearchLoading: false });
  };
  render() {
    const unitChange=(event,foodid)=>{
      const index= this.state.Foods.findIndex(i=>i.id===foodid);
      let changedObject= this.state.Foods[index];
      let selectedUnitId=parseInt(event.target.value);
      if(selectedUnitId!==0)
      {
       let selectedUnit=this.state.Foods[index].units.filter(i=>i.id===selectedUnitId)[0];
       changedObject.selectedUnitWeight=selectedUnit.weight;
       changedObject.selectedUnitId=selectedUnit.id;
       changedObject.selectedUnitValue=1;
       // changedObject.selectedCalorieValue=changedObject.selectedUnitWeight*changedObject.selectedUnitValue*(changedObject.calorieValue/100);
       // changedObject.selectedCarbValue=changedObject.selectedUnitWeight*changedObject.selectedUnitValue*(changedObject.carbValue/100);
      }
      else{//Gram girilmiş
       changedObject.selectedUnitId=0;
       changedObject.selectedUnitValue=100;
       changedObject.selectedUnitWeight=1;

      }
      changedObject.selectedCalorieValue=(changedObject.selectedUnitWeight*changedObject.selectedUnitValue)*(changedObject.calorieValue/100);
      changedObject.selectedCarbValue= (changedObject.selectedUnitWeight*changedObject.selectedUnitValue)*(changedObject.carbValue/100);
      changedObject.selectedFatValue= (changedObject.selectedUnitWeight*changedObject.selectedUnitValue)*(changedObject.fatValue/100);
      changedObject.selectedProteinValue= (changedObject.selectedUnitWeight*changedObject.selectedUnitValue)*(changedObject.proteinValue/100);
      var newData = update(this.state.Foods, {
       $splice: [[index, 1, changedObject]]
   });
   this.setState({Foods: newData});

   };
   const unitValueChange=(event,foodid)=>{
     const index= this.state.Foods.findIndex(i=>i.id===foodid);
     let changedObject= this.state.Foods[index];
     changedObject.selectedUnitValue=event.target.value;
     changedObject.selectedCalorieValue= (changedObject.selectedUnitWeight*changedObject.selectedUnitValue)*(changedObject.calorieValue/100);
     changedObject.selectedCarbValue= (changedObject.selectedUnitWeight*changedObject.selectedUnitValue)*(changedObject.carbValue/100);
     changedObject.selectedFatValue= (changedObject.selectedUnitWeight*changedObject.selectedUnitValue)*(changedObject.fatValue/100);
     changedObject.selectedProteinValue= (changedObject.selectedUnitWeight*changedObject.selectedUnitValue)*(changedObject.proteinValue/100);
     var newData = update(this.state.Foods, {
       $splice: [[index, 1, changedObject]]
   });
     this.setState({Foods: newData});
   };
   const onSaveCalorie = async (event,food) => {
    event.preventDefault();
    const foodList =this.state.SelectedFoods;
    let obj = {       
      UnitValue:parseFloat(isNaN(food.selectedUnitValue)?100:food.selectedUnitValue),
      CalorieId:parseInt(food.id),
      UnitId:parseInt(isNaN(food.selectedUnitId)?0:food.selectedUnitId),
      UserId:this.state.User.id,
      Name:food.name,
      ImageUrl:food.imageUrl
    };
    if(!foodList.some(i=>i.CalorieId==obj.CalorieId))
    foodList.push(obj);
    this.setState({SelectedFoods:foodList})
    console.log(this.state.SelectedFoods);

  };
    return (
      <div>
           <Modal
          onClose={() => this.setState({ ModalShow: false })}
          open={this.state.ModalShow}
        >
          <Modal.Header>
          Yasal Bilgilendirme
          </Modal.Header>
          <Modal.Content>
          Tarif gönderim koşullarını kabul ettiğinizde gönderdiğiniz tarif ve fotoğrafların CalorieHunter tarafından kullanılmasını, düzenlenmesini ve yayınlanmasını kabul etmiş sayılırsınız.
          Tarif yayınlandıktan sonra tarifin yayından kaldırılması hususundaki son kararı CalorieHunter verecektir.
          Tarif ve eklediğiniz tüm fotoğrafların size ait olduğunu kabul etmiş sayılırsınız. Aksi durumda oluşabilecek bütün hukuki sorumluluk tarifi gönderen kişiye aittir.

          </Modal.Content>
          <Modal.Actions>
            <button
              className="btn btn-success"
              onClick={() => this.setState({ ModalShow: false })}
            > 
              Kapat
            </button>
          </Modal.Actions>
        </Modal>
                 <Modal
          onClose={() => this.setState({ MeterialModalShow: false })}
          open={this.state.MeterialModalShow}
        >
          <Modal.Header>
          Kullanılan Mazemeler
          </Modal.Header>
          <Modal.Content>
          <div className="col-12">

        <div className="table-wrapper">
            <div className="table table-responsive">
              <div className="ttop">
                <div className="thead">
                  <div className="tr">
                    <div className="td">#</div>
                    <div className="td">Besin</div>
                    <div className="td">Sil</div>

                  </div>
                </div>
                <div className="tbody">
                  {this.state.SelectedFoods.map((f) => (
                    <div className="tr" key={f.id}>
                      <div className="td">
                        <img
                        className="profile-img"
                        alt=""
                        src={f.ImageUrl} />
                      </div>
                      <div className="td">{f.Name}</div>
                      <div className="td">{ <Button onClick={() => this.deleteFood(f)}>Sil</Button> }</div>
                     
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <form onSubmit={this.onCalorieSearchSubmitHandler}>
          <div className="row align-items-end">
            <div className="form-group col-12 col-sm-11 col-lg-11">
              <label htmlFor="SearchWord">
                Tarife eklemek istediğiniz besini arayın
              </label>
              <input
                className="form-control"
                name="SearchWord"
                id="SearchWord"
                onChange={this.onChangeHandler}
              />
            </div>
            <div className="form-group col-12 col-sm-1 col-lg-1">
              <Button
                type="submit"
                color="primary"
                disabled={this.state.SearchLoading}
              >
                {this.state.SearchLoading && (
                  <i className="ri-loader-4-line ri-spin"></i>
                )}
                {!this.state.SearchLoading && <span> Ara</span>}
                {this.state.SearchLoading && <span> Yükleniyor ...</span>}
              </Button>
              
            </div>
          </div>
        </form>
        <div className="table-wrapper">
            <div className="table table-responsive">
              <div className="ttop">
                <div className="thead">
                  <div className="tr">
                    <div className="td"></div>
                    <div className="td"></div>
                    <div className="td"></div>
                    <div className="td"></div>
                    <div className="td"></div>
                    {this.props.token && (
                      <div>                    
                     
                        <div className="td"></div>
                      </div>
                      )}

                  </div>
                </div>
                <div className="tbody">
                  {this.state.Foods.map((f) => (
                    <div className="tr" key={f.id}>
                      <div className="td">
                        <img
                        className="profile-img"
                        alt=""
                        src={f.imageUrl} />
                      </div>
                      <div className="td">{f.name}</div>
                      <div className="td">
                    
              <div className="col-12 ">
                <select
                  className="form-control"
                  onChange={(event)=> unitChange(event,f.id)}
                  type="select"
                >
                  <option key={0}value={0}>Gram</option>
                  {f.units.map((unit) => (
                    <option key={unit.id} value={unit.id}>
                      {unit.unitName}
                    </option>
                  ))}
                </select>
          </div>
                      </div>
                      <div className="td">
                      <input
                        className="form-control"
                        step="1"
                        type="number"
                        value={f.selectedUnitValue}
                        onChange={(event)=> unitValueChange(event,f.id)}
                />           
                      </div>
                      <div className="td"><p>{f.selectedCalorieValue.toFixed(1)}-{f.selectedCarbValue.toFixed(1)}-{f.selectedFatValue.toFixed(1)}-{f.selectedProteinValue.toFixed(1)} <br/>kcal-karb-Yağ-Protein</p></div>
                      {this.props.token && (
                        <div>
                          <div className="td">
                          <Button
                           color="success"
                           type="submit"
                           onClick={(event)=> onSaveCalorie(event,f)}
                          > 
                          <span>Ekle</span> 
                         </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
          </Modal.Content>
          <Modal.Actions>
            <button
              className="btn btn-success"
              onClick={() => this.setState({ MeterialModalShow: false })}
            >
              Kapat
            </button>
          </Modal.Actions>
        </Modal>
        <div className="content-title">Tarif Detayı</div>
        <form action="" onSubmit={this.onSubmitHandler}>
          <div className="row"> 
          <div className="form-group col-12 col-sm-6 col-lg-3">
              <label htmlFor="Name">Adı</label>
              <input
                className="form-control"
                name="Name"
                id="Name"
                onChange={this.onChangeHandler}
                value={this.state.Name}
                required
              />
            </div>
          <div className="form-group col-12 col-sm-6 col-lg-3">
                <label htmlFor="exampleBoy">Hazırlanma Süresi</label>
                <input
                  className="form-control"
                  type="number"
                  name="PrepareTime"
                  id="PrepareTime"
                  value={this.state.PrepareTime}
                  onChange={this.onChangeHandler}
                  required
                />
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-3">
                <label htmlFor="exampleBoy">Pişirme Süresi</label>
                <input
                  className="form-control"
                  type="number"
                  name="CookingTime"
                  id="CookingTime"
                  value={this.state.CookingTime}
                  onChange={this.onChangeHandler}
                  required
                />
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-3">
                <label htmlFor="exampleBoy">Kaç Kişilik</label>
                <input
                  className="form-control"
                  type="number"
                  name="Capacity"
                  id="Capacity"
                  value={this.state.Capacity}
                  onChange={this.onChangeHandler}
                  required
                />
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-3">
              <label htmlFor="RecipeCategoryId">Kategori:</label>
              <div className="form-select">
                <select
                  className="form-control"
                  value={this.state.RecipeCategoryId}
                  type="select"
                  name="RecipeCategoryId"
                  id="RecipeCategoryId"
                  onChange={this.onChangeHandler}
                >
                  <option key={0}value={0}>Seçiniz</option>
                  <option key={1}value={1}>Tatlılar</option>
                  <option key={2}value={2}>İçecekler</option>
                  <option key={3}value={3}>Atıştırmalıklar</option>
                </select>
              </div>
            </div>
          <div className="form-group col-12 col-sm-6 col-lg-3">
              <label>Video</label>
              <input
                type="file"
                accept=".mp4"
               
              />
            </div>
            <div className="form-group col-12 col-sm-6 col-lg-3">
              <div className="btn btn-info" type="button" onClick={() => this.setState({ MeterialModalShow: true })} >Mazemeler</div>
            </div>
            <div className="form-group col-12 col-sm-12 col-lg-12">
              <label htmlFor="Explanation">Hazırlanışı</label>
              <textarea
                className="form-control"
                value={this.state.Explanation}
                name="Explanation"
                id="Explanation"
                onChange={this.onChangeHandler}
                required
              />
            </div>

            <div className="form-group col-12 col-sm-12 col-lg-12">
              <label>Tarif Resimleri(*Kapak fotoğrafı yapmak istediğiniz resmi sürükleyerek ilk resim yapın.)</label>
              <input
                type="file"
                accept=".jpg,.jpeg,.png"
                onChange={this.handleFile}
                multiple
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-12 col-sm-12 col-lg-12">
              <DragDropContext onDragEnd={(e) => this.handleDragEnd(e)}>
                <Droppable droppableId="list" direction="horizontal">
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      sx={{
                        width: "100%",
                        maxWidth: 360,
                        bgcolor: "background.paper",
                      }}
                    >
                      {this.state.Files &&
                        this.state.Files.map((item, index) => {
                          return (
                            <Draggable
                              key={item.id + item.file.name}
                              draggableId={item.id.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  className="profile-unlogin d-none d-xl-inline-block ml-1 text-center"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  elevation={2}
                                  sx={{ marginBottom: "10px" }}
                                >
                                  <img
                                    className="recipeuploaded-img"
                                    src={item.img}
                                    alt=""
                                  />
                                  <div
                                    type="none"
                                    color="info"
                                    className="text-center mt-1 btn btn-info"
                                    onClick={()=>this.deleteImage(item)}
                                  >
                                    Sil
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
        
          </div>
          <div className="row">
          <div className="form-group col-12 col-sm-12 col-lg-12">
              <label  className="recipe-switch mt-2" htmlFor="Privaciy">
                <span  className="text-info" onClick={() => this.setState({ ModalShow: true })} >Tarif gönderim koşullarını</span> kabul ediyorum.</label>
              <input
               name="Privaciy"
               className="ml-2 text-center"
               type="checkbox"
               value={this.state.Privaciy}
               checked={this.state.Privaciy}
               onChange={this.onChangeHandler}
               required
            />
            </div>
          </div>
          <Button
            color="success"
            className="mt-3 mr-3"
            type="submit"
            disabled={this.state.Loading}
          >
            {this.state.Loading && <i className="fa fa-refresh fa-spin"></i>}
            {!this.state.Loading && <span>KAYDET</span>}
            {this.state.Loading && <span> Kaydediliyor ...</span>}
          </Button>
        </form>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return { token: state.authReducer,user:state.userReducer };
}
export default connect(mapStateToProps)(RecipeUpload);
