import React, { Component } from 'react';
import Login from './Login';
import Register from '../pages/NewUser';
import { connect } from 'react-redux';
import Layout from '../layout/layout';
import { bindActionCreators } from "redux";
import { changeTheme } from '../../redux/actions/rootAction';
import { changeLoading } from '../../redux/actions/loadingAction';
import { logaut } from "../../redux/actions/authAction";
import { getUser } from "../../redux/actions/userAction";
import { Router, Route,Switch } from "react-router-dom";
import SwitchBox from "../../components/Switch";
import Logo from "../../images/logo.svg";
import NoPictureDark from "../../images/no-picture-dark.svg";
import NoPictureLight from "../../images/no-picture-light.svg";
import BodyMass from "../pages/BodyMass";
import DailyFoods from "../DailyFoods";
import Loading from "../Loading"
import RecipeDetail from "../pages/RecipeDetail";
import UserDetail from "../pages/UserDetail";
import CalorieRequeriment from '../pages/CalorieRequeriment';
import CalorieRegister from '../pages/CalorieRegister';
import HowManyCalorie from '../pages/HowManyCalorie';
import AdminCalorieSearch from '../pages/AdminCalorieSearch';
import RecipeUpload from '../pages/RecipeUpload';
import BaseRecipes from '../pages/BaseRecipes';

import history from "../../history";
import "../../../src/App.css";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import PostList from '../PostList';

class App extends Component {
  componentWillMount() {
    if (localStorage.getItem('theme')) {
      let isDarkMode = localStorage.getItem('theme') === 'dark';
      this.props.actions.changeTheme(localStorage.getItem('theme'));
      if (isDarkMode) {
        document.body.classList.add('dark');
      } else {
        document.body.classList.remove('dark');
      }
    }
  }


  async componentDidMount() {
    this.props.actions.changeLoading(false);
   console.log(this.props.loading);
  }
  state = {
    //operationClaims: [],
    user: JSON.parse(this.props.user),
    isOpen: false
  };
  toggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }
  toggleSwitch = (value) => {
    let valueToStore = value ? "light" : "dark";
    this.props.actions.changeTheme(valueToStore);
    if (!value) {
      document.body.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.body.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  };
  onClick = (event) => {
     sessionStorage.removeItem("Token");
     sessionStorage.removeItem("User");
     this.props.actions.logaut();
     this.props.actions.getuser();
  };
  pageChange(page) {
    history.push("/" + page);
  };
  loginUser=()=> {
    console.log("adsd");
  };
  getImage() {
    if(this.props.user!==null)
    {
      let image=JSON.parse(this.props.user).imagePath;
      if(image!=="")
        return JSON.parse(this.props.user).imagePath;
    }
    if(this.props.theme==="light")
      return NoPictureLight
    else
      return NoPictureDark;
    
      
  };
  render() {
    return (
      <div>
          <header>
          <div className="container">
          <Navbar className="navbar navbar-light navbar-expand-xl col-12">
              <NavbarBrand onClick={() => this.pageChange("")}>               
                <img className="profile-img" src={Logo} alt="" />
              </NavbarBrand>
              <NavbarToggler onClick={() => this.toggle()} />
              <Collapse
                isOpen={this.state.isOpen}
                className="collapse navbar-collapse"
              >
                <Nav className="navbar-nav m-auto mt-2">
                <UncontrolledDropdown className="nav-item position-static dropdown" nav inNavbar > 
                 <DropdownToggle onClick={() => this.pageChange("HowManyCalorie")} className="nav-link dropdown-toggle" nav caret > Kaç Kalori </DropdownToggle>

                 </UncontrolledDropdown>  
                <UncontrolledDropdown className="nav-item position-static dropdown" nav inNavbar >                       
                    <DropdownToggle className="nav-link dropdown-toggle" nav caret > Hesaplamalar </DropdownToggle>
                     <DropdownMenu className="dropdown-menu w-100">
                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-3 dropdown-col">
                        <DropdownItem  onClick={() => this.pageChange("BodyMass")}>
                          Vücut Kitle İndeksi
                          </DropdownItem>
                      </div>
                      <div className="col-12 col-md-6 col-lg-3 dropdown-col">
                          <DropdownItem onClick={() => this.pageChange("CalorieRequeriment")}>
                            Kalori İhtiyacı
                          </DropdownItem>
                      </div>
                    </div>
                   </DropdownMenu>      
                 </UncontrolledDropdown>
                 <UncontrolledDropdown className="nav-item position-static dropdown" nav inNavbar >                       
                    <DropdownToggle onClick={() => this.pageChange("BaseRecipes")}  className="nav-link dropdown-toggle" nav caret > Fit Yemekler </DropdownToggle>
                     {/* <DropdownMenu className="dropdown-menu w-100">
                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-3 dropdown-col">
                        <DropdownItem>Tatlılar</DropdownItem>
                      </div>
                      <div className="col-12 col-md-6 col-lg-3 dropdown-col">
                          <DropdownItem>İçecekler</DropdownItem>
                      </div>
                    </div>
                   </DropdownMenu>       */}
                 </UncontrolledDropdown>   
                 {this.props.token && (
                 <UncontrolledDropdown className="nav-item position-static dropdown" nav inNavbar >        
                   <DropdownToggle className="nav-link dropdown-toggle" nav caret >Admin İslemleri</DropdownToggle>       
                   <DropdownMenu className="dropdown-menu w-100">     
                   <div className="row">
                      <div className="col-12 col-md-6 col-lg-3 dropdown-col">
                           <DropdownItem onClick={() => this.pageChange("CalorieRegister")} className="nav-link dropdown-toggle" nav caret >Besin Kayıt</DropdownItem>    
                      </div>
                      <div className="col-12 col-md-6 col-lg-3 dropdown-col">
                          <DropdownItem onClick={() => this.pageChange("AdminCalorieSearch")} className="nav-link dropdown-toggle" nav caret >Besin Listesi</DropdownItem>  
                      </div>
                    </div>   
                   </DropdownMenu>      
                   
                 </UncontrolledDropdown>   
                  )}
                   {this.props.token && (
                     <UncontrolledDropdown className="nav-item position-static dropdown" nav inNavbar > 
                     <DropdownToggle onClick={() => this.pageChange("RecipeUpload")} className="nav-link dropdown-toggle" nav caret > Tarif Gönder</DropdownToggle>
    
                     </UncontrolledDropdown>     
                  )}
                    {/* Mobil Menu Başlangıç*/}
                    <div class="d-block d-xl-none">               
                      {!this.props.token && (
                        
                        <UncontrolledDropdown className="nav-item position-static dropdown" nav inNavbar 
                            onClick={() => this.pageChange("Login")} >
                            Giriş Yap
                        </UncontrolledDropdown>
                      )}
                     {!this.props.token && (
                    <UncontrolledDropdown className="nav-item position-static dropdown" nav inNavbar 
                        onClick={() => this.pageChange("Register")}
                      >
                        Kayıt Ol
                        </UncontrolledDropdown>
                      )}
                      <UncontrolledDropdown className="nav-item position-static dropdown" nav inNavbar >
                       <div className="switch-container">
                          <span className="switch-text">Dark Mode</span>
                          <SwitchBox
                            toggleSwitch={(value) => this.toggleSwitch(value)}
                            value={this.props.theme === "dark"}
                          />
                        </div>
                      </UncontrolledDropdown>
                      {this.props.token && (
                        <UncontrolledDropdown className="nav-item position-static dropdown" nav inNavbar 
                            onClick={() => this.pageChange("UserDetail")} >
                            Hesabım
                        </UncontrolledDropdown>
                      )}
                      <UncontrolledDropdown className="nav-item position-static dropdown logout" nav inNavbar
                        onClick={() => this.onClick()}
                      >
                        Logout
                        </UncontrolledDropdown>   
                  </div>  
                 {/*Mobil Menu Bitiş */}     
                </Nav>
                <div className="nav-item position-static dropdown profile-item">
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle
                      id="profile-menu"
                      className="nav-link dropdown-toggle p-0"
                      nav
                      caret
                    >
                      {this.props.token && (
                      <img
                        className="profile-img d-none d-xl-inline-block"
                        // src={this.state.user !==null && this.state.user.imagePath!==""?this.state.user.imagePath:this.getTempImage()}
                        src={this.getImage()}
                        alt=""
                      />
                      )}
                      {!this.props.token && (
                      <div
                      className='profile-unlogin d-none d-xl-inline-block'
                      >
                        <div className='row'>
                          <div className='col-12 login-text ml-2'>Giriş Yap</div>
                          <div className='col-12 register-text ml-2'>veya Kayıt Ol</div>
                          </div>                        
                      </div>
                        )}
                      <span className="profile-name d-inline d-xl-none"></span>
                    </DropdownToggle>
                    <DropdownMenu right>
                      {/* <DropdownItem className="dropdown-item">
                        Profile
                      </DropdownItem>
                      <DropdownItem className="dropdown-item">
                        Settings
                      </DropdownItem> */}
                      {!this.props.token && (
                      <DropdownItem
                        className="dropdown-item"
                        onClick={() => this.pageChange("Login")}
                      >
                        Giriş Yap
                      </DropdownItem>
                         )}
                     {!this.props.token && (
                      <DropdownItem
                        className="dropdown-item"
                        onClick={() => this.pageChange("Register")}
                      >
                        Kayıt Ol
                      </DropdownItem>
                      )}
                      {this.props.token && (
                      <DropdownItem
                        className="dropdown-item"
                        onClick={() => this.pageChange("UserDetail")}
                      >
                        Hesabım
                      </DropdownItem>
                      )}
                      
                      <div className="dropdown-item">
                        <div className="switch-container">
                          <span className="switch-text">Dark Mode</span>
                          <SwitchBox
                            toggleSwitch={(value) => this.toggleSwitch(value)}
                            value={this.props.theme === "dark"}
                          />
                        </div>
                      </div>

                      <DropdownItem
                        className="dropdown-item logout"
                        onClick={() => this.onClick()}
                      >
                        Logout
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </Collapse>
            </Navbar>          
          </div>
          </header>
          <div className="page-content">
          <div className="main">    
            <div className="container">
             {/* <DailyFoods/>  */}
   
              <Router history={history}>
                <Switch>
                  <Route component={BodyMass} exact path={"/BodyMass"} />
                  <Route component={CalorieRequeriment} exact path={"/CalorieRequeriment"} />
                  <Route loginUser={()=>this.loginUser} component={Login} exact path={"/Login"} />
                  <Route component={Register} exact path={"/Register"} />
                  <Route component={Layout} exact path={"/"} />
                  <Route component={CalorieRegister} exact path={"/CalorieRegister"} />
                  <Route component={AdminCalorieSearch} exact path={"/AdminCalorieSearch"} />
                  <Route component={HowManyCalorie} exact path={"/HowManyCalorie"} />
                  <Route component={RecipeUpload} exact path={"/RecipeUpload"} />
                  <Route component={BaseRecipes} exact path={"/BaseRecipes"} />        
                  <Route component={RecipeDetail} exact path={"/RecipeDetail"} />    
                  <Route component={UserDetail} exact path={"/UserDetail"} />       
                </Switch>
              </Router>
            </div>
          </div>
        </div>
        {this.props.loading && (
        <Loading />)}
        {/* {isLogin()} */}
        {/* <Layout /> */}
        {/* <footer>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="copyright">
                  © Calorie Hunter 2023 • All Rights reserved.
                </div>
              </div>
            </div>
          </div>
        </footer> */}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {     
      changeTheme: (data) => dispatch(changeTheme(data)),
      changeLoading: (data) => dispatch(changeLoading(data)),
      logaut: bindActionCreators(logaut, dispatch),
      getuser: bindActionCreators(getUser, dispatch),
    },
  };
}
function mapStateToProps(state) {
  return { token: state.authReducer, theme: state.rootReducer.theme,user:state.userReducer,loading:state.loadingReducer.loading };
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
