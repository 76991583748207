import React, { Component } from 'react'
import 'react-datepicker/dist/react-datepicker.css';
import { Button } from 'reactstrap';
export default class CalorieRequeriment extends Component {
  state = {

    Gender: '0',
    Height:175,
    Weight:70,
    Age:30,
    Bmr:0,
    ActivityLevel:1.2
  };
  onChangeHandler = (event) => {
    event.preventDefault();
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value });
   
  };
  onActivityLevelHandler = (value) => {
    this.setState({ActivityLevel:value});   
  };
  onSubmitHandler = (event) => {
    event.preventDefault();
    let bmr=0;
    if(this.state.Gender==="0")
    {
      bmr=(66.5 + (13.75*this.state.Weight)+(5.003 * this.state.Height)-(6.75 * this.state.Age))*this.state.ActivityLevel;
    
    }
    else{
      bmr=(655.1 + (9.563*this.state.Weight)+(1.850 * this.state.Height)-(4.676 * this.state.Age))*this.state.ActivityLevel;
    }
    this.setState({Bmr:bmr.toFixed(1)});
  };
  render() {
    return (
      <div>
           <div className="content-title">Kalori İhtiyacı</div>
           <form action="" onSubmit={this.onSubmitHandler}>
           <div className="row">
           <div className="form-group col-12 col-sm-6 col-lg-3">
              <label htmlFor="name">Cinsiyet</label>
              <div className="form-select">
                <select
                  name="Gender"
                  id="Gender"
                  onChange={this.onChangeHandler}
                  className="form-control"
                  value={this.state.Gender}
                >
                  <option value="0"> Erkek</option>
                  <option value="1"> Kadın </option>
                </select>
              </div>
            </div>
            <div className="form-group col-12 col-sm-6 col-lg-3">
                <label htmlFor="exampleBoy">Boy</label>
                <input
                  className="form-control"
                  type="number"
                  name="Height"
                  id="exampleBoy"
                  value={this.state.Height}
                  onChange={this.onChangeHandler}
                  required
                />
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-3">
                <label htmlFor="exampleKilo">Kilo</label>
                <input
                  className="form-control"
                  type="number"
                  name="Weight"
                  id="exampleKilo"
                  value={this.state.Weight}
                  onChange={this.onChangeHandler}
                  required
                />
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-3">
                <label htmlFor="exampleBoy">Yaş</label>
                <input
                  className="form-control"
                  type="number"
                  name="Age"
                  id="exampleBoy"
                  value={this.state.Age}
                  onChange={this.onChangeHandler}
                  required
                />
              </div>
          <div className="form-group col-12"><span>Gün içi hareketlilik seviyeniz?</span></div>
          <div className="form-group col-12">
          <input type="radio" name="ActivityRadio"  onChange={()=>this.onActivityLevelHandler(1.2)}/> 
              Sedanter (Hareket etmiyorum veya çok az hareket ediyorum.) 
          </div>
          <div className="form-group col-12">
          <input type="radio" name="ActivityRadio" onChange={()=>this.onActivityLevelHandler(1.375)}/> 
               Az hareketli (Hafif hareketli bir yaşam / Haftada 1-3 gün egzersiz yapıyorum.)
          </div>
          <div className="form-group col-12">
          <input type="radio" name="ActivityRadio" value="1.55" onChange={()=>this.onActivityLevelHandler(1.55)}/> 
               Orta derece hareketli (Hareketli bir yaşam / Haftada 3-5 gün egzersiz yapıyorum.)
          </div>
          <div className="form-group col-12">
          <input type="radio" name="ActivityRadio" value="1.725" onChange={()=>this.onActivityLevelHandler(1.725)}/> 
          Çok hareketli (Çok hareketli bir yaşam / Haftada 6-7 gün egzersiz yapıyorum.)
          </div>
          <div className="form-group col-12">
          <input type="radio" name="ActivityRadio" value="1.9" onChange={()=>this.onActivityLevelHandler(1.9)}/> 
             Aşırı hareketli (Profesyonel sporcu, atlet seviyesi.)
          </div>
          

        <div className="form-group col-12 col-sm-6 col-lg-3">
              <Button color="success" className="mt-4" type="submit"> 
              <span>Hesapla</span> 
          </Button>
          </div>

          </div>
          <div className="row">
          <span>Günlük kalori ihtiyacınız:{this.state.Bmr}</span> 
          </div>
          </form>
      </div>

    )
  }
}
