import React, { Component  } from 'react'

import Collapse, { Panel } from "rc-collapse";

import { connect } from 'react-redux';


class DailyFoods extends Component {
    state = {
        User: JSON.parse(this.props.user),
        breakfast:'collapse show',
      };
    
  render() {
    return (
      <div>DailyFoods
       {/* {this.props.token && (
        this.state.User.userDailyFoods.map((val) => (
          <div className="td" key={val}>
          <p>{val.name}</p>
        </div>
        ))
       )} */}
    <div>
    <Collapse accordion={true}>
    <Panel header="hello" headerClass="my-header-class">
      this is panel content
    </Panel>
    <Panel header="title2">this is panel content2 or other</Panel>
  </Collapse>
        
      </div>
      </div>
    )
  }
}
function mapStateToProps(state) {
    return { token: state.authReducer,user:state.userReducer };
  }
  export default connect(mapStateToProps)(DailyFoods);
