import React, { Component } from 'react'
import axios from 'axios';
import { Button,Table } from 'reactstrap';
import * as Config from '../../config';
import { Buffer } from 'buffer';
import S3 from 'react-aws-s3';
import alertify from 'alertifyjs';
import history from '../../history';
import { connect } from 'react-redux';
class CalorieRegister extends Component {
  state = {
    Name:'',
    CalorieValue:0,
    Carb:0,
    Fat:0,
    Protein:0,
    Sugar:0,
    File: '',
    Loading: false,
    ImageUrl:'',
    BrandId:0,
    MainCategoryId:0,
    SubCategoryId:0,
    Brads:[],
    MainCategorys:[],
    SubCategorys:[],
    Units:[],
    RecordUserId:0,
    CalorieId:0,
    UnitName:'',
    Weight:0,
    Order:0,
    Status:true,
    Id:0,
    User: JSON.parse(this.props.user),
  };
  onActiveHandler = event => {
    switch (event.target.value) {
      case "true":
        this.setState({ Status: true });
        break;

      default:
        this.setState({ Status: false });
        break;
    }
  };
  async getUnit()
  {
    await axios.get( 
      Config.ApiUrl + "api/unit/getbycalorieid?calorieid=" +
      this.state.CalorieId).then(response => {
        this.setState({ Units: response.data });
      }).catch(error => { 
      });;
  }
  async componentDidMount() {
    if (this.props.token) {
    await axios
      .get(Config.ApiUrl + 'api/brand/getall')
      .then((c) => {
        this.setState({ Brads: c.data });
      })
      .catch((error) => {
        console.log(error.response);
      });
      await axios
      .get(Config.ApiUrl + 'api/maincategory/getall')
      .then((c) => {
        this.setState({ MainCategorys: c.data });
      })
      .catch((error) => {
        console.log(error.response);
      });

      if (history.location.state && history.location.state.id) {
        const calorieid=parseInt(history.location.state.id);
        this.setState({ CalorieId: calorieid });
        this.getUnit();
        await axios.get( 
            Config.ApiUrl + "api/calorie/getbycalorieid?id=" +
            calorieid).then(r => {
              this.setState({ BrandId: r.data.brandId });
              this.setState({ CalorieValue: r.data.calorieValue });
              this.setState({ Carb: r.data.carb });
              this.setState({ MainCategoryId: r.data.categoryId });
              this.setState({ Fat: r.data.fat });
              this.setState({ Id: r.data.id });
              this.setState({ ImageUrl: r.data.imageUrl });
              this.setState({ Name: r.data.name });
              this.setState({ Protein: r.data.protein });
              this.setState({ RecordUserId: r.data.recordUserId });
              this.setState({ SubCategoryId: r.data.subCategoryId });
              this.setState({ Sugar: r.data.sugar });
              this.setState({ Status: r.data.status });
            }).catch(error => { 
            });;
      }
    }
    else
    history.push("/");
}
  handleFile = (e) => {
    var blob = e.target.files[0].slice(0, e.target.files[0].size);
    var newFile = new File(
      [blob],
      e.target.files[0].name.substring(0, e.target.files[0].name.indexOf('.')) +
        Date.now() +
        '.png',
      { type: 'image/png' }
    );
    this.setState({ File: newFile });
  };
  onUnitSubmitHandler=async (event) => {
    event.preventDefault();
    this.setState({ Loading: true });
    let obj = {       
      Status: true,
      UnitName:this.state.UnitName,
      Weight:parseFloat(this.state.Weight),
      CalorieId:parseInt(this.state.CalorieId),
      Order:parseInt(this.state.Order),
    };
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.token}`;
    await axios
    .post(Config.ApiUrl + "api/unit/add", obj)
    .then((response) => {
      alertify.success(response.data.message, 4);
    })
    .catch((error) => {
      alertify.error(error.response.data, 4);
    });
    this.getUnit();
    this.setState({ Loading: false });
  }
   deleteUnit = async (event) => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.token}`;
    await axios
    .post(Config.ApiUrl + "api/unit/delete", event)
    .then((response) => {
      alertify.success(response.data, 4);
    })
    .catch((error) => {
      alertify.error(error.response.data, 4);
    });
    this.getUnit();
   }
  onSubmitHandler = async (event) => {
    event.preventDefault();
    this.setState({ Loading: true });
    if (this.state.File !== '') {
      window.Buffer = Buffer;
      const ReactS3Client = new S3(Config.S3CalorieImageconfig);

      await  ReactS3Client
      .uploadFile(this.state.File, this.state.File.name)
      .then(data => {
        this.setState({ ImageUrl: data.location });
        })
      .catch(err => console.error(err));
      }

      let obj = {    
        Id:this.state.Id,   
        Status: this.state.Status,
        ImageUrl:this.state.ImageUrl,
        RecordUserId:this.state.User.id,
        CalorieValue:parseFloat(this.state.CalorieValue),
        CategoryId:parseInt(this.state.MainCategoryId),
        SubCategoryId:parseInt(this.state.SubCategoryId),
        Fat:parseFloat(this.state.Fat),
        Carb:parseFloat(this.state.Carb),
        Protein:parseFloat(this.state.Protein),
        Sugar:parseFloat(this.state.Sugar),
        BrandId:parseInt(this.state.BrandId),
        Name:this.state.Name,
      };
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.token}`;
      await axios
      .post(Config.ApiUrl + "api/calorie/add", obj)
      .then((response) => {
        alertify.success(response.data.message, 4);
        this.setState({ CalorieId: parseInt(response.data.data.id) });
      })
      .catch((error) => {
        alertify.error(error.response.data, 4);
      });
    
      this.setState({ Loading: false });

      //history.push("/");

 
    
  };
  onChangeHandler = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value });
  };
  MainCategoryChangeHandler = async (event) => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value });
    await axios.get( 
      Config.ApiUrl + "api/subcategory/getbymaincategoryid?maincategoryid=" +
      parseInt(value)).then(response => {
        this.setState({ SubCategorys: response.data });
      }).catch(error => {
       
      });;
  };
  render() {
    return (
      <div>
        <div className="content-title">Besin Kaydet</div>
        <form action="" onSubmit={this.onSubmitHandler}>
        <div className="row">
        <div className="form-group col-12 col-sm-6 col-lg-3">
                <label htmlFor="Name">Besin Adı</label>
                <input
                  className="form-control"
                  name="Name"
                  id="Name"
                  value={this.state.Name}
                  onChange={this.onChangeHandler}
                  required
                />
       </div>
       <div className="form-group col-12 col-sm-6 col-lg-3">
                <label htmlFor="CalorieValue">Kalori</label>
                <input
                  className="form-control"
                  step="0.1"
                  type="number"
                  name="CalorieValue"
                  id="CalorieValue"
                  value={this.state.CalorieValue}
                  onChange={this.onChangeHandler}
                  required
                />
         </div>
         <div className="form-group col-12 col-sm-6 col-lg-3">
                <label htmlFor="Carb">Karbonhidrat</label>
                <input
                  className="form-control"
                  step="0.1"
                  type="number"
                  name="Carb"
                  id="Carb"
                  value={this.state.Carb}
                  onChange={this.onChangeHandler}
                  required
                />
         </div>
         <div className="form-group col-12 col-sm-6 col-lg-3">
                <label htmlFor="Fat">Yağ</label>
                <input
                  className="form-control"
                  step="0.1"
                  type="number"
                  name="Fat"
                  id="Fat"
                  value={this.state.Fat}
                  onChange={this.onChangeHandler}
                  required
                />
         </div>
         <div className="form-group col-12 col-sm-6 col-lg-3">
                <label htmlFor="Protein">Protein</label>
                <input
                  className="form-control"
                  step="0.1"
                  type="number"
                  name="Protein"
                  id="Protein"
                  value={this.state.Protein}
                  onChange={this.onChangeHandler}
                  required
                />
         </div>
         <div className="form-group col-12 col-sm-6 col-lg-3">
                <label htmlFor="Sugar">Sugar</label>
                <input
                  className="form-control"
                  step="0.1"
                  type="number"
                  name="Sugar"
                  id="Sugar"
                  value={this.state.Sugar}
                  onChange={this.onChangeHandler}
                  required
                />
         </div>
         <div className="form-group col-12 col-sm-6 col-lg-3">
              <label htmlFor="Brand">Marka:</label>
              <div className="form-select">
                <select
                  className="form-control"
                  value={this.state.BrandId}
                  type="select"
                  name="BrandId"
                  id="BrandId"
                  onChange={this.onChangeHandler}
                >
                  <option key={0}value={0}>Seçiniz</option>
                  {this.state.Brads.map((brand) => (
                    <option key={brand.id} value={brand.id}>
                      {brand.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-group col-12 col-sm-6 col-lg-3">
              <label htmlFor="MainCategoryId">Kategori:</label>
              <div className="form-select">
                <select
                  className="form-control"
                  value={this.state.MainCategoryId}
                  type="select"
                  name="MainCategoryId"
                  id="MainCategoryId"
                  onChange={this.MainCategoryChangeHandler}
                >
                  <option key={0}value={0}>Seçiniz</option>
                  {this.state.MainCategorys.map((maincategory) => (
                    <option key={maincategory.id} value={maincategory.id}>
                      {maincategory.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-group col-12 col-sm-6 col-lg-3">
              <label htmlFor="SubCategoryId">Alt Kategori:</label>
              <div className="form-select">
                <select
                  className="form-control"
                  value={this.state.SubCategoryId}
                  type="select"
                  name="SubCategoryId"
                  id="SubCategoryId"
                  onChange={this.onChangeHandler}
                >
                  <option key={0}value={0}>Seçiniz</option>
                  {this.state.SubCategorys.map((subcategory) => (
                    <option key={subcategory.id} value={subcategory.id}>
                      {subcategory.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {this.state.Id ===0 && (
         <div className="form-group col-12 col-sm-6 col-lg-3">
                <label>Besin Resmi</label>
                <input
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  onChange={this.handleFile}
                />
              </div>
          )}
         <div className="form-group col-12 col-sm-6 col-lg-3">
              <label htmlFor="Status">Durum:</label>
              <div className="form-select">
                <select
                  className="form-control"
                  name="Status"
                  id="Status"
                  onChange={this.onActiveHandler}
                  value={this.state.Status}
                >
                  <option value={true}> Aktif </option>
                  <option value={false}> Pasif </option>
                </select>
              </div>
            </div>
         <div className="form-group col-12 col-sm-6 col-lg-3">
         <Button
            color="success"
            className="mr-3"
            type="submit"
            disabled={this.state.Loading}
          >
            {!this.state.Loading && (
              <span>Kaydet</span>
            )}
          </Button>
         </div>
   
        </div>
        </form>
        <div className="content-title">Ölçü Birimi Kaydet</div>
       
        <div className="row">
        <div className="form-group col-12 col-sm-6 col-lg-3">
                <label htmlFor="UnitName">Ölçü Adı</label>
                <input
                  className="form-control"
                  name="UnitName"
                  id="UnitName"
                  value={this.state.UnitName}
                  onChange={this.onChangeHandler}
                  required
                />
       </div>
       <div className="form-group col-12 col-sm-6 col-lg-3">
                <label htmlFor="Weight">Ağırlık</label>
                <input
                  className="form-control"
                  step="0.1"
                  type="number"
                  name="Weight"
                  id="Weight"
                  value={this.state.Weight}
                  onChange={this.onChangeHandler}
                  required
                />
         </div>
         <div className="form-group col-12 col-sm-6 col-lg-3">
                <label htmlFor="Order">Sıra No</label>
                <input
                  className="form-control"
                  step="1"
                  type="number"
                  name="Order"
                  id="Order"
                  value={this.state.Order}
                  onChange={this.onChangeHandler}
                  required
                />
         </div>
         <Button
            color="success"
            className="mr-3"
            onClick={this.onUnitSubmitHandler}
            disabled={this.state.Loading}
          >
            {!this.state.Loading && (
              <span>Kaydet</span>         
            )}
          </Button>
                     <Table>
          <thead>
            <tr>
              <th>Ölçü Adı</th>
              <th>Ağırlık</th>
              <th>Sıra No</th>
              <th>Sil</th>
            </tr>
          </thead>
          <tbody>
            {this.state.Units.map(e => (
              <tr key={e.id}>
                <td>{e.unitName}</td>    
                <td>{e.weight}</td>      
                <td>{e.order}</td>      
                <td>
                  <Button onClick={() => this.deleteUnit(e)}>Sil</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

       </div>

      </div>
    )
  }
}
function mapStateToProps(state) {
  return { token: state.authReducer,user:state.userReducer };
}
export default connect(mapStateToProps)(CalorieRegister);