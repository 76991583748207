import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import * as Config from "../../config";
import history from "../../history";
import { Modal } from 'semantic-ui-react';
class UserDetail extends Component {
  state = {
    ImageUrl: "",
    FollowerModalShow: false,
    FollowUpModalShow: false,
    NickName: "",
    FollowerCount: 0,
    FollowUpCount: 0,
    PostCount:0,
    Followers:[],
    FollowUppers:[],
    Loading:false,
    User: JSON.parse(this.props.user),
  };
  async componentDidMount() {
    if (history.location.state && history.location.state.id) {
      await axios
        .get(
          Config.ApiUrl +
            "api/users/getuserdetail?userId=" +
            history.location.state.id
        )
        .then((r) => {
          this.setState({ PostCount: r.data.data.postCount });
          this.setState({ ImageUrl: r.data.data.imageUrl });
          this.setState({ NickName: r.data.data.nickName });
          this.setState({ FollowerCount: r.data.data.followers.length });
          this.setState({ FollowUpCount: r.data.data.followUpCount });
          this.setState({ Followers: r.data.data.followers });
        })
        .catch((error) => {});
    }
  }
  onSubmitHandler = async () => {

    this.setState({ Loading: true });
    history.push({
      pathname: '/'
    });
    this.setState({ Loading: false });
  };
  async getFollowers() {
    this.setState({ FollowerModalShow: true });
    //if (history.location.state && history.location.state.id) {
      // await axios
      //   .get(
      //     Config.ApiUrl +
      //       "api/userrelation/getfolloweruserlist?userId=" +
      //       history.location.state.id
      //   )
      //   .then((r) => {
      //     this.setState({ Followers: r.data.data });
      //     console.log(r.data.data);
      //   })
      //   .catch((error) => {});
   // }
  }
  async FollowUppers() {
    this.setState({ FollowUpModalShow: true });
    if (history.location.state && history.location.state.id) {
      await axios
        .get(
          Config.ApiUrl +
            "api/userrelation/getfollowupuserlist?userId=" +
            history.location.state.id
        )
        .then((r) => {
          this.setState({ FollowUppers: r.data.data });
        })
        .catch((error) => {});
    }
  }
  render() {
    return (
      <div>
        <Modal
          onClose={() => this.setState({ FollowerModalShow: false })}
          open={this.state.FollowerModalShow}
        >
          <Modal.Header>
          Takipçiler
          </Modal.Header>
          <Modal.Content>
          <div className="table-wrapper">
            <div className="table table-responsive">
              <div className="ttop">
                <div className="thead">
                  <div className="tr">
                    <div className="td"></div>
                    <div className="td"></div>
                  </div>
                </div>
                <div className="tbody">
                  {this.state.Followers.map((f) => (
                    <div className="tr" key={f.id}>
                      <div className="td">
                        <img
                        className="profile-img"
                        alt=""
                        src={f.imageUrl} />
                      </div>
                      <div className="td">{f.nickName}</div>

                     
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          </Modal.Content>
          <Modal.Actions>
            <button
              className="btn btn-success"
              onClick={() => this.setState({ FollowerModalShow: false })}
            > 
              Kapat
            </button>
          </Modal.Actions>
        </Modal>
        <Modal
          onClose={() => this.setState({ FollowUpModalShow: false })}
          open={this.state.FollowUpModalShow}
        >
          <Modal.Header>
          Takip Edilenler
          </Modal.Header>
          <Modal.Content>

          </Modal.Content>
          <Modal.Actions>
            <button
              className="btn btn-success"
              onClick={() => this.setState({ FollowUpModalShow: false })}
            > 
              Kapat
            </button>
          </Modal.Actions>
        </Modal>
        <div className="content-title">{this.state.NickName}</div>
        <div className="row">
          <div className="col-12 col-sm-6 col-lg-4">
            <img className="img-fluid" src={this.state.ImageUrl} />
          </div>
          <div className="col-12 col-sm-6 col-lg-8">
            <div className="table-wrapper">
              <div className="table table-responsive">
                <div className="ttop">
                  <div className="thead">
                    <div className="tr">
                      <div className="td">Gönderiler</div>
                      <div className="td cursorpointer"  onClick={() => this.getFollowers()}>Takipçi</div>
                      <div className="td cursorpointer"  onClick={() => this.FollowUppers()}>Takip</div>
                      {this.state.User && history.location.state && (history.location.state.id !=this.state.User.id) && (
                        <div className="td"></div>
                      )}
                    </div>
                  </div>
                  <div className="tbody">
                    <div className="tr">
                      <div className="td">{this.state.PostCount}</div>
                      <div className="td cursorpointer"   onClick={() => this.getFollowers()}>{this.state.FollowerCount}</div>
                      <div className="td cursorpointer"   onClick={() => this.FollowUppers()}>{this.state.FollowUpCount}</div>
                      {this.state.User && history.location.state && (history.location.state.id !=this.state.User.id) && (
                        <div className="td cursorpointer"  onClick={() => this.FollowUppers()}>Takip Et</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className=" col-12 col-sm-6 col-lg-4">
          {this.props.token && (
                     <button className="btn btn-success mt-2 w-100" type="submit"  onClick={() => this.onSubmitHandler()} >
                     Takip Et
                   </button>
                         )}
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return { token: state.authReducer, user: state.userReducer };
}
export default connect(mapStateToProps)(UserDetail);
