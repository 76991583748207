import { combineReducers } from 'redux';
import authReducer from './authReducer';
import rootReducer from './rootReducer';
import userReducer from './userReducer';
import loadingReducer from './loadingReducer';

const reducers = combineReducers({
  authReducer,
  rootReducer,
  userReducer,
  loadingReducer,
});
export default reducers;
