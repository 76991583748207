import React, { Component } from "react";
import { connect } from 'react-redux';
import { Button,Card,CardTitle,CardText } from "reactstrap";
import Collapse, { Panel } from "rc-collapse";
import S3 from 'react-aws-s3';
import * as Config from '../config';
import { Buffer } from 'buffer';
import axios from 'axios';
import alertify from 'alertifyjs';
import history from '../history';
class BaseView extends Component {
  state = {
    user: JSON.parse(this.props.user),
    Loading: false,
    Imagelocation:'',
    Explanation:'',
    Collapse:true,
    File:[],
  };
 uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }
  
  onChangeHandler = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value });
  };

  handleFile = (e) => {
    var blob = e.target.files[0].slice(0, e.target.files[0].size);
    var newFile = new File(
      [blob],
      this.uuidv4() +
        '.png',
      { type: 'image/png' }
    );
    this.setState({ File: newFile });
  };
    onSubmitHandler = async (event) => {
      
        this.setState({ Loading: true });
        if (this.state.File !== '') {
          window.Buffer = Buffer;
          const ReactS3Client = new S3(Config.PostImageConfig);
         
       await  ReactS3Client
        .uploadFile(this.state.File, this.state.File.name)
        .then(data => {
          this.setState({ Imagelocation: data.location });
          })
        .catch(err => console.error(err));
         }
       let obj = {
        UserId:this.state.user.id,
        ImageUrl: this.state.Imagelocation,
        Explanation:this.state.Explanation
      };
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.token}`;

        await axios
        .post(Config.ApiUrl + "api/post/add", obj)
        .then((response) => {
          alertify.success("Gönderi Paylaşıldı!", 4);
          history.push("/");
        })
        .catch((error) => {
          alertify.error(error.response.data, 4);
        });
        this.setState({ Collapse: false });
        this.setState({ Loading: false });
      };
  render() {
    return (
      <div className="row">
        <div className="col-12">
        <Collapse accordion={this.state.Collapse}>
    <Panel header="Sende Gönderi Paylaş...!" >
    <Card body>
            {/* <CardTitle tag="h5">Paylaş</CardTitle> */}
            <CardText>
            <div className="form-group col-12">
              <label htmlFor="Explanation">Yorum:</label>
              <textarea
                className="form-control"
                name="Explanation"
                id="Explanation"
                onChange={this.onChangeHandler}
              />
            </div>
            </CardText>
            <div className="row">
            <div className="form-group col-6">
              <label>Resim Ekle</label>
              <input
                type="file"
                accept=".jpg,.jpeg,.png"
                onChange={this.handleFile}
                multiple
              />
            </div>
            <Button className="btn-info col-6 mt-4" onClick={()=>this.onSubmitHandler()}>Paylaş</Button>
 
            </div>
            
            
          </Card>
    </Panel>
  
  </Collapse>
        
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return { token: state.authReducer,user:state.userReducer  };
}
export default connect(mapStateToProps)(BaseView);