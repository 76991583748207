import React, { Component } from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import * as moment from 'moment';
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ExpandMore from "@mui/icons-material/ExpandMore";
import * as Config from "../../config";
import axios from "axios";
import history from "../../history";
import { connect } from 'react-redux';
import { changeLoading } from '../../redux/actions/loadingAction';
class BaseRecipes extends Component {
  state = {
    Recipes: [],
  };
  async componentDidMount() {
    this.props.actions.changeLoading(true);
    await axios
      .get(Config.ApiUrl + "api/recipe/getrecipesearchview")
      .then((response) => {
        this.setState({ Recipes: response.data });
        this.props.actions.changeLoading(false);
      })
      .catch((error) => {  this.props.actions.changeLoading(false);});

  }
  render() {
    return (
      <div>
        <div className="content-title">Yemek Tarifleri</div>
        <div className="row">
          {this.state.Recipes.map((val) => (
            <div className="col-12 col-sm-6 col-lg-4 mt-2" key={val.id}>
              <Card> 
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                        <img
                        className="profile-img"
                        onClick={() => history.push({ pathname:'/UserDetail',state:{id:val.uploadUserId}})}
                        src={val.uploadUserImageUrl}
                        alt=""
                      />
                    </Avatar>
                  }
                  // action={
                  //   <IconButton aria-label="settings">
                  //     <MoreVertIcon />
                  //   </IconButton>
                  // }
                  title={val.recipeName}
                  subheader={moment(val.recordDate).format('DD.MM.YYYY')}
                />
                <CardMedia
                  component="img"
                  height="194"
                  className="recipeimg"
                  image={val.recipeImageUrl}
                  alt="Recipe Image"
                  onClick={()=> history.push({ pathname:'/RecipeDetail',state:{id:val.id}})}
                  
                />
                <CardContent  className="recipeimg"  onClick={()=> history.push("/")}>
                  Hazırlık Süresi:{val.prepareTime} dk,  Pişirme Süresi:{val.cookingTime} dk
               
                </CardContent>
                <CardActions disableSpacing>
                  <IconButton aria-label="add to favorites">
                    <FavoriteIcon />
                  </IconButton>
                </CardActions>
              </Card>
            </div>
          ))}
         
        </div>
      </div>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {     
      changeLoading: (data) => dispatch(changeLoading(data)),
    },
  };
}
function mapStateToProps(state) {
  return { loading:state.loadingReducer.loading };
}
export default connect(mapStateToProps, mapDispatchToProps)(BaseRecipes);
